body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  font-size: 16px;
}
*, *::after, *::before {
  box-sizing: border-box;
}

html,
body,
#root,
#root > .App {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@300;500&display=swap');
html,
body {
  height: 100vh;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.screens-main-container {
  display: flex;
  min-width: 100%;
  height: 100vh;
}

.screens-right-container {
  background-color: #6d0fb1;
  margin: 40px;
  flex: 0 1 auto;
  width: 640px;
  height: auto;
  display: flex;
  justify-content: center;
}

.screens-logo-container {
  align-self: flex-end;
  margin-bottom: 40px;
}

.screens-left-container {
  flex: 0 1 50%;
  min-width: 720px;
  margin: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;
}

.screens-text-container {
  max-width: 70%;
  display: flex;
  flex-flow: column wrap;
  margin-left: 80px;
}

.screens-text-container h1 {
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 60px;
  line-height: 78px;
  color: #2e384d;
}

.screens-text-container h2 {
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 26px;
  line-height: 40px;
  color: #2e384d;
}

.screens-boxname-container {
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 40px;
  color: #2e384d;
  align-self: flex-start;
  margin-left: 80px;
  display: flex;
  flex-direction: row;
}

.screens-boxname-container span {
  margin-left: 19px;
}
